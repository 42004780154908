import React from "react";

const Disclaimer = () => {
  return (
    <div className="pt-[100px] pl-3 sm:pl-10 pr-3 sm:pr-10 pb-10 font-roboto">
      <h1 className="font-bold text-lg text-center underline">
        Driver Terms & Condition
      </h1>

      <div className="mt-5">
        <h1 className="font-bold text-lg">OVERVIEW</h1>
        <p className="mt-2 font-medium text-base">
          This document is an electronic record as per the provisions of
          Information Technology Act, 2000 and rules framed there under as
          applicable and the provisions pertaining to electronic records in
          various statutes as amended by the Information Technology Act, 2000.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures. By clicking on the "I
          ACCEPT/AGREE" button on the Platform, You are consenting to be bound
          by this Agreement for using the Platform for the purpose of receiving
          and delivering the Services. PLEASE ENSURE THAT YOU READ AND
          UNDERSTAND ALL THE CLAUSES BEFORE YOU USE THE SERVICE OF THE PLATFORM,
          AS YOU SHALL BE BOUND BY ALL THE TERMS AND CONDITIONS CONTAINED HEREIN
          upon clicking on the "I ACCEPT" button. If You do not accept any of
          this Agreement, please do not use the Platform or avail any of the
          Services being provided therein. YOUR AGREEMENT SHALL OPERATE AS A
          BINDING AGREEMENT BETWEEN YOU AND GARUDAYAAN LOGISTICS IN RESPECT OF
          THE SERVICES OF THE PLATFORM.
        </p>
        <p className="mt-2 font-medium text-base">
          Your engagement with GARUDAYAAN LOGISTICS to use the services of the
          Platform are subject to your acceptance of the Clauses as specified in
          this Agreement. Garudayaan Logistics reserves the right, at its sole
          discretion, to change, modify, add or remove these terms and
          conditions, in part or in whole, at any time, without prior notice to
          You. It is Your responsibility and duty to check the terms and
          conditions periodically for changes. Your continued use of the
          Platform following the posting of changes will mean that You accept
          and agree to the changes. As long as You comply with this Agreement,
          Garudayaan Logistics grants You a personal, non-exclusive,
          non-transferable, limited privilege to enter and use the Service on
          the Platform.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg">DEFINITIONS</h1>
        <p className="mt-2 font-medium text-base">
          Unless the context otherwise provides or requires, the following words
          and expressions used in this Agreement shall have the meaning as
          provided to them herein below:
        </p>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            "Acceptance" means your affirmative action of clicking on the box
            against the words "I Accept"provided at the end of these Driver
            Partner's Agreement including the T&C, by which action, you
            unequivocally accept the Driver Partner T&C and any modifications
            thereof.
          </li>
          <li className="font-medium text-base">
            "Agreement" shall mean the Services Agreement executed between
            Garudayaan Logistics and the Service Provider pursuant to which the
            Driver Partner has agreed to undertake Services.
          </li>
          <li className="font-medium text-base">
            "Applicable Law" shall mean and include any law, statute, ordinance,
            rule, regulation, guideline, policy, notification, circular or other
            pronouncement or any similar form of decision of, or determination
            by or any interpretation, administration and other pronouncements
            having the effect of law of the Republic of India or any other
            applicable jurisdiction by state, municipality, court, tribunal,
            government, ministry, department, commission, arbitrator or board
            having the effect of law in India, as in effect or which may come in
            effect on a future date.
          </li>
          <li className="font-medium text-base">
            "Business Day" means a day (other than national gazette holidays,
            and bank holidays in the Territory) on which nationalized banks are
            generally open in India for the conduct of banking business and
            comprising of normal working hours."
          </li>
          <li className="font-medium text-base">
            "Driver Partner" or "Service Provider" or "You" or "Your" or
            "Yourself" shall mean an individual who has executed Agreement for
            the provision of Services.
          </li>
          <li className="font-medium text-base">
            "Driver Partner information" shall mean and include any personal
            data collected from the Driver Partner including know your client
            documents with Driver Partner's bank, copies of valid government
            issued Vehicle registration certificate, Vehicle insurance copy,
            driving license, identity proof, residence proof, location data,
            proof of ownership, registration and compliance under welfare
            legislations for Driver Partner and any other information that
            Garudayaan Logistics may deem fit.
          </li>
          <li className="font-medium text-base">
            "Driver Services" shall mean either or both of the following as the
            context may require:
          </li>
          <li className="font-medium text-base">
            The services provided by the Driver Partner to Garudayaan Logistics
            of making himself available on the Platform for the purpose of
            receiving orders placed by the Users.
          </li>
          <li className="font-medium text-base">
            The services provided by the Driver Partner to the customers on
            Platform whereby the Driver Partner collects the
            goods/products/consignments ordered by the customers/consignors and
            delivers the same to the consignees.
          </li>
          <li className="font-medium text-base">
            "Governmental Authority" means any governmental or any governmental
            agency, semi-governmental or judicial entity or authority,
            department of law and order including the Police personnel of any
            rank (including, without limitation, any stock exchange or any
            self-regulatory organisation established under statute).
          </li>
          <li className="font-medium text-base">
            "Intellectual Property Rights" shall mean and include the copyright
            (whether registered or not), patents including rights of filing
            patents, trademarks, trade names, trade dresses, house marks,
            collective marks, associate marks and the right to register them,
            designs (both industrial and layout), geographical indicators, moral
            rights, broadcasting rights, displaying rights, distribution rights,
            selling rights, reproducing rights, domain names, internet address,
            graphics, artwork, links, information, logos, software,
            configuration, marketing and distribution material and any other
            intellectual property right in the website of Garudayaan Logistics.
            in and Garudayaan Logistics Platform, which are used and/or owned by
            Garudayaan Logistics.
          </li>
          <li className="font-medium text-base">
            "Platform Services" shall have the meaning assigned to it in the
            Agreement.
          </li>
          <li className="font-medium text-base">
            "Fees" means the consideration payable by Garudayaan Logistics to
            the Driver Partner for providing the Services as contemplated under
            this Agreement as per the General Terms and Conditions.
          </li>
          <li className="font-medium text-base">
            "Services" means the Driver and Platform Services technology-based
            services provided by Garudayaan Logistics for the transportation of
            its goods/products/consignments from one place to another for the
            hiring of Vehicles by customers for a point to point service, or for
            time and usage based service within city limits and outside the city
            limits, including inter-city all over India, through the internet
            and / or mobile telecommunications devices including hiring of
            labour by customers for loading/unloading of the consignment. And
            such other services which Garudayaan Logistics may from time to time
            add.
          </li>
          <li className="font-medium text-base">
            "Tax" means all forms of present and future taxes (including but not
            limited to Indirect Taxes), deductions, withholdings, duties,
            imposts, levies, cesses, fees, charges, social security
            contributions and rates imposed, levied, collected, withheld or
            assessed by any Governmental Authority or other taxing authority in
            India or elsewhere and any interest, additional taxation, penalty,
            surcharge, cess or fine in connection therewith and "Taxes" shall be
            construed accordingly.
          </li>
          <li className="font-medium text-base">
            "Termination Date" shall mean the date on which these Partner T&C
            and the Agreement shall stand terminated.
          </li>
          <li className="font-medium text-base">
            "Terms and Conditions" or "Driver Partner T&C" refers to these
            Driver Partner Terms and Conditions, which are available on the
            Platform, as may be amended from time to time and morefully
            prescribed under Annexure B and/or C.
          </li>
          <li className="font-medium text-base">
            "We" or "Us" or "Our" or " Garudayaan Logistics ", A company
            incorporated under the Companies Act, 1956, and having its
            registered office at Survey No. 183/1, Lake Side road behind NCC
            apartment mahadevapura outer ring road Bangalore 560048 which
            expression shall, unless it be repugnant to the context or meaning
            thereof, be deemed to mean and include all our successors,
            affiliates and assigns.
          </li>
          <li className="font-medium text-base">
            "Person" means any natural person, limited or unlimited liability
            company, corporation, partnership (whether limited or unlimited),
            proprietorship, Hindu undivided family, trust, union, association,
            Governmental Authority or any agency or political subdivision
            thereof or any other entity that may be treated as a person under
            the Applicable Law.
          </li>
          <li className="font-medium text-base">
            "Platform" shall have the meaning assigned to it in the Agreement.
            It shall also include for all purposes the mobile
            application/website interface provided to the Driver Partner wherein
            all the back-end technology is incorporated to enable the Driver
            Partner to provide Services as well as enable Garudayaan Logistics,
            to track the orders/requests and other relevant information.
          </li>
          <li className="font-medium text-base">
            "Vehicle" shall have the meaning which the Driver Partner will use
            for performing the Services.
          </li>
        </ol>
      </div>

      <div className="mt-5 ">
        <h1 className="mt-2 font-bold text-lg">INTERPRETATION</h1>
        <p className="mt-2 font-medium text-base">
          Unless the context of this Agreement otherwise requires:
        </p>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            The recitals, annexure, terms and conditions, attachments, fees
            receipts, invoices and schedules shall constitute an integral and
            operative part of this Agreement.
          </li>
          <li className="font-medium text-base">
            A word or an expression, which denotes a natural person, shall
            include an artificial person (and vice versa), any one gender shall
            include the other genders, the singular shall include the plural
            (and vice versa).
          </li>
          <li className="font-medium text-base">
            Heading and bold typeface in this Agreement are for convenience only
            and shall be ignored for the purposes of interpretation.
          </li>
          <li className="font-medium text-base">
            The use of the word "including" followed by a specific example(s) in
            these Agreement, shall not be construed as limiting the meaning of
            the general wording preceding it.
          </li>
          <li className="font-medium text-base">
            The rule of construction, if any, that any clause should be
            interpreted against the parties responsible for drafting and
            preparation thereof, shall not apply.
          </li>
          <li className="font-medium text-base">
            Reference to any clause, article, section or schedule shall mean a
            reference to a clause, article, section or schedule of this
            Agreement, unless specified otherwise.
          </li>
          <li className="font-medium text-base">
            Reference to any Applicable Law shall mean a reference to that law
            as a mended, consolidated, supplemented or replaced.
          </li>
          <li className="font-medium text-base">
            All references to this agreements, documents or other instruments
            include all documents attached to this Agreement (subject to all
            relevant approvals) a reference to this agreement, document or
            instrument as amended, supplemented, substituted, novated or
            assigned from time to time.
          </li>
          <li className="font-medium text-base">
            Unless the context otherwise requires, the singular includes the
            plural and vice versa.
          </li>
          <li className="font-medium text-base">
            The words 'hereof', 'herein', and 'hereto' and words of similar
            import when used with reference to a specific clause in, or Schedule
            to, this Agreement shall refer to such clause in, or Schedule to,
            this Agreement, and when used otherwise than in connection with
            specific clauses or Schedules, shall refer to the Agreement as a
            whole.
          </li>
          <li className="font-medium text-base">
            Headings and the use of bold typeface shall be ignored in its
            construction.
          </li>
          <li className="font-medium text-base">
            A reference to a Clause or Schedule is, unless indicated to the
            contrary, a reference to a clause or schedule to this Agreement.
          </li>
          <li className="font-medium text-base">
            References to this Agreement shall be construed as references also
            to any separate or independent stipulation or agreement contained in
            it.
          </li>
          <li className="font-medium text-base">
            The words "other” or otherwise and "whatsoever" shall not be
            construed ejusdem generis or be construed as any limitation upon the
            generality of any preceding words or matters specifically referred
            to.
          </li>
          <li className="font-medium text-base">
            .References to any law shall include references to such law as it
            may, after the date of this Agreement, from time to time be amended,
            supplemented or re-enacted. And,
          </li>
          <li className="font-medium text-base">
            Words and abbreviations, which have, well known technical or
            trade/commercial meanings, are used in the Agreement in accordance
            with such meanings.
          </li>
        </ol>
      </div>

      <div className="mt-5 space-y-3">
        <h1 className="mt-2 font-bold text-lg">
          APPLICABILITY OF THE TERMS AND CONDITIONS
        </h1>
        <p className="font-medium text-base">
          The Driver Partner T&C together with the Agreement shall form the
          complete understanding between the Parties. By accepting these Driver
          Partner T&C, You acknowledge and agree to the Driver Partner T&C and
          Garudayaan Logistics policies that makes applicable to You from time
          to time, to the fullest extent possible.
        </p>
        <p className="font-medium text-base">
          Unless defined herein, the words, phrases and capitalized terms, which
          are contained or referred to in the Agreement, shall be construed as
          having the meaning thereby attributed to them in the Agreement.
        </p>
        <p className="font-medium text-base">
          Subject to the terms of this Agreement, Garudayaan Logistics engages
          the Driver Partner to perform, and You hereby agree to perform, the
          Services, with reference to the documents as prescribed under Annexure
          A, as instructed by Garudayaan Logistics. The Parties may from time to
          time agree to the provision of additional services by executing
          amendments / addendum to this Agreement or by way of an
          email/letter/e-notifications and alerts, which shall also be
          considered as an integral part of this Agreement.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">TERM AND TERMINATION</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            The present Agreement shall be valid for a period of 1 year w.e.f.
            the Execution Date of this Agreement unless terminated by either
            Party in accordance with the terms of this Agreement by giving a 15
            (Fifteen) days' notice in writing to the other Party.
          </li>
          <li className="font-medium text-base">
            This Agreement shall become effective on the Effective Date and
            shall remain in full force with automatic renewal of the agreement
            by the Parties (unless terminated by Garudayaan Logistics).
          </li>
          <li className="font-medium text-base">
            Driver as service partner agrees for the documents to be sent for
            background verification and this contract stands null and void and
            the services terminated with immediate effect if there is any
            discrepancy found.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics may terminate this Agreement of the Service
            Provider without assigning any reason whatsoever.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics shall be entitled to terminate this Agreement
            forthwith without any notice thereof, if the Service Provider does
            not provide the Services in accordance with the scale-up plan agreed
            for the Services.
          </li>
          <li className="font-medium text-base">
            Termination of this Agreement shall be without prejudice to the
            acquired rights and obligations of the parties existing at the date
            of termination (including the right to take action in respect of the
            circumstances giving rise to termination) and shall not affect any
            other rights and remedies available to the party terminating the
            Agreement.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          REPRESENTATIONS AND WARRANTIES
        </h1>
        <p className="mt-2 font-medium text-base">
          On the Effective Date, the Service Provider hereby represents and
          warrants that the following representations and warranties are true,
          correct, valid and subsisting in all respects:
        </p>
        <p className="mt-2 font-medium text-base">
          In order to register as a Service Provider, the Service Provider must
          be above 18 years of age and is enrolled with all the welfare schemes
          as provided by any Governmental Authority and must share the requisite
          documents with Garudayaan Logistics from time to time for compliance
          purposes only.
        </p>
        <h1 className="mt-2 font-bold text-lg">
          You acknowledge and agree that at all times the Service Provider must
          hold and maintain:
        </h1>

        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            A valid driver's license (if applicable) with the appropriate level
            of certification to operate the Vehicle driven/ridden by You, and
          </li>
          <li className="font-medium text-base">
            All licenses, permits, approvals and authority applicable to the
            Vehicle (if applicable) that are necessary to provide the Services.
          </li>
          <li className="font-medium text-base">
            The appropriate and current level of training, expertise and
            experience to provide the Services in a professional manner with due
            skill, care and diligence. And
          </li>
          <li className="font-medium text-base">
            High standards of professionalism, service and courtesy. You
            acknowledge and agree that You may be subject to certain background
            and driving record checks from time to time.
          </li>
        </ol>
        <p className="mt-2 font-medium text-base">
          The Service Provider confirms that Garudayaan Logistics does not own
          ordinary way control or own the Vehicles used by a passenger or any
          driver rendering the said services to the customer, whether employed
          by the Service Provider or not. Garudayaan Logistics shall not be held
          liable or responsible in any manner whatsoever for any insufficiency
          or deficiency of service rendered by the Service Provider to the
          consignor/consignee.
        </p>
        <p className="mt-2 font-medium text-base">
          The Service Provider agrees and acknowledges that the upon acceptance
          of an order by the Service Provider, Services undertaken by him shall
          constitute a separate contract for services under the Applicable Laws
          or any successor legislations, between the Service Provider and the
          User, to which Garudayaan Logistics is not a party.
        </p>
        <p className="mt-2 font-medium text-base">
          Not with standing anything as contained here in and in addition to the
          responsibilities of the Service Provider specifically in the segment
          of 2 (Two) wheeler Vehicles, the Service Provider also being a driver
          which may or may not be under his ownership as to the right, title and
          interest on the Vehicle, is responsible and liable for all loss or
          damages as to any matter arising from the Vehicle including the
          claims, demands, charges, expenses, show cause, detention, litigation
          and but not limited to objections, etc. as may be raised by a third
          party, family or related members, appropriate authorities, police
          functionary, etc. and shall keep Garudayaan Logistics indemnified from
          all such claims from time to time during the course during the term of
          this Agreement.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">FEES AND TAXES</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            In consideration for performance of the Services in accordance with
            the requirements of this Agreement, the Service Provider shall be
            entitled to receive the Fees on or after deduction of applicable
            Taxes.
          </li>
          <li className="font-medium text-base">
            The applicable financial terms for the Vehicle shall be communicated
            from time to time on Garudayaan Logistics notice board of Your City
            Office and/or Branch Location.
          </li>
          <li className="font-medium text-base">
            The Fees in relation to the trip fare will be calculated through the
            GPS device provided
          </li>
          <li className="font-medium text-base">
            All payments due to the Service Provider shall be made by a crossed
            pay order cheque in favour of Name of Service provider or through
            net banking / NEFT / RTGS etc., as mutually agreed from time to
            time.
          </li>
          <li className="font-medium text-base">
            The Service Provider do hereby authorize Garudayaan Logistics to
            collect any payments against the Services and to make such payments
            to the account as provided and consented to Garudayaan Logistics by
            the Service Provider from time to time. Garudayaan Logistics hereby
            disclaims all liabilities which may arise from all obligations under
            this clause as the acts will be performed as per the prior and
            voluntary consent provided by the Service Provider in own
            discretion. The Service Provider have no objection on any of the
            abovementioned commercial transactions with Garudayaan Logistics.
          </li>
          <li className="font-medium text-base">
            Before making payments to the Service Provider, Garudayaan Logistics
            shall be entitled to deduct commission or its own service charges
            including the deduction of Taxes at source.
          </li>
          <li className="font-medium text-base">
            The Service Provider acknowledges and agrees that in the event, any
            Tax proceedings are initiated against Garudayaan Logistics, the
            Service Provider shall fully cooperate with Garudayaan Logistics by
            furnishing the relevant information related to the supply provided
            to Garudayaan Logistics on a timely basis as may be required by
            Garudayaan Logistics. Furthermore, all the damages and other costs
            incurred by Garudayaan Logistics due to any fault of the Service
            Provider, the Service Provider shall indemnify Garudayaan Logistics
            for all such damages and other costs.
          </li>
          <li className="font-medium text-base">
            The Fees payable to the Service Provider is exclusive of all
            applicable central, state and other Taxes at the time of execution
            of this Agreement. and
          </li>
          <li className="font-medium text-base">
            The Service Provider shall be solely responsible to pay the Taxes or
            any other applicable taxes with respect to the provision of Services
            provided to Garudayaan Logistics
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          SERVICE PROVIDER'S OBLIGATIONS
        </h1>
        <h1 className="mt-2 font-bold text-lg">
          Each Person hereby obligates that:
        </h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            The Service Provider shall not have more than one active Account (as
            defined hereinafter) on the Platform. Additionally, the Service
            Provider are prohibited from selling, trading, or otherwise
            transferring Your Account to another party.
          </li>
          <li className="font-medium text-base">
            Service Provider hereby provides consent to Garudayaan Logistics to
            access, use or share data and information including but not limited
            to location information, contacts information, transaction
            information, usage and preference information, device information,
            call and SMS data and Log information related to subscription to
            Garudayaan Logistics platform. The Service Provider expressly
            confirms that some of the call related information may be personal
            in nature.
          </li>
          <li className="font-medium text-base">
            The Service Provider is capable of entering in the present
            Agreement, pursuant to the Indian Contract Act, 1872 and is not
            below the age of 18 years as on the Effective Date.
          </li>
          <li className="font-medium text-base">
            All Service Provider Information provided shall be true and correct
            and no information that could impact the Service Provider's
            performance to render Services pursuant to these Service Provider
            T&C and shall not be hidden from Garudayaan Logistics anytime during
            the subsistence of these Service Provider T&C.
          </li>
          <li className="font-medium text-base">
            The Service Provider has not been convicted by any court in India or
            any other country of any crimes including but not limited to
            involving moral turpitude. Further, the Service Provider is not a
            party to any pending litigation, which shall materially affect Your
            obligations under these Service Provider T&C.
          </li>
          <li className="font-medium text-base">
            All licenses, permits, approvals and authority applicable to the
            Vehicle utilized by You that are necessary to provide Services.
          </li>
          <li className="font-medium text-base">
            The appropriate and current level of training, expertise and
            experience to provide the Services in a professional manner with due
            skill, care and diligence.
          </li>
          <li className="font-medium text-base">
            You acknowledge and agree that You may be subject to certain
            background and driving record checks from time to time.
          </li>
          <li className="font-medium text-base">
            You shall not use Garudayaan Logistics Platform's information but
            not limited thereto ("Confidential Information") for any purpose
            other than to exercise its rights and perform its obligations under
            or in connection with this Agreement.
          </li>
          <li className="font-medium text-base">
            You shall ensure all obligations related to a comprehensive
            insurance including without limitation insurance of Vehicles, third
            party, goods, driver, customer etc. to meet any accidental
            eventuality etc. and the hirer/consignor/consignee shall not be
            liable for any thing whatsoever.
          </li>
          <li className="font-medium text-base">
            The Service Provider also shall furnish all the details including
            the drivers as per Annexure B and/or C for security reasons and
            ensure that the Service Provider is registered with Garudayaan
            Logistics.
          </li>
          <li className="font-medium text-base">
            The Service Provider shall ensure that the customer pays the Vehicle
            fare (as displayed in the installed technology firmware and/or
            Platform, or that is otherwise agreed upon by Garudayaan Logistics
            and the Service Provider at the time of hire), parking charges,
            additional night surcharge (where applicable) and any fee or levy
            presently payable or hereinafter imposed by law or required to be
            paid for hiring the Vehicle (for cash bookings). Toll charges will
            be payable by the customer (whether by way of cash or credit, as
            previously agreed between Garudayaan Logistics and Service Provider)
            as applicable, when the Vehicle that is in use and hired by the
            customer through Garudayaan Logistics crosses a toll post.
          </li>
          <li className="font-medium text-base">
            The Service Provider will have a functioning mobile number and also
            have the ability to read and send SMSs from Garudayaan Logistics,
            regarding the customer details, kms and time reading, amount paid by
            the customer and to convey customer feedback. Service Provider shall
            maintain all documents pertaining to the personal details and where
            about so fall.
          </li>
          <li className="font-medium text-base">
            In case any Service Provider wants to go on leave, Service Provider
            will inform Garudayaan Logistics at least 3 days in advance and the
            Service Provider shall provide a placement driver for the duration
            of absence with a separate enrolment under the Platform. The
            replacement driver must undergo the required training, at the
            Service Provider's cost, and as per Garudayaan Logistics set quality
            standards.
          </li>
          <li className="font-medium text-base">
            The Service Provider will be maintaining certain standard ratings on
            the Platform which will be depending upon the transparent process
            and parameters of Garudayaan Logistics, failure to adhere with the
            rating process will have an impact in the Services which will be
            solely decided by Garudayaan Logistics from time to time.
          </li>
          <li className="font-medium text-base">
            During the Services are performed, the Vehicle shall not be pilfered
            or tampered with and the same shall be reported immediately by the
            Service Provider directly to Garudayaan Logistics.
          </li>
          <li className="font-medium text-base">
            The Service Provider must be aware of the prominent destinations and
            routes, both outside and within the Branch Location.
          </li>
          <li className="font-medium text-base">
            Any discounts given to the customer, if any, will be decided by
            Garudayaan Logistics based on a case to case basis and Service
            Provider shall agree to the same without demur or protest.
          </li>
          <li className="font-medium text-base">
            The Service Provider shall ensure and shall be solely responsible to
            ensure that before the Pickup, the device/phone is available for the
            passenger and Garudayaan Logistics. The Service Provider's phone
            should not be busy for long, unavailable or switched off. Doing this
            will lead to deductions in the amounts to be paid to Service
            Provider by adjusting the discounts given to the passenger with the
            amount to be paid to Service Provider. Such deductions may extend up
            to any quantum of the amounts to be paid to the Service Provider.
            and
          </li>
          <li className="font-medium text-base">
            The Service Provider shall be liable for all claims and demands
            raised by any third party at all times in respect of the Vehicle
            (owned or hired) which ever the case may be. Garudayaan Logistics
            does not verify the details of the Vehicle used by the Service
            Provider. In case of any claims as to the title of the Vehicle
            between the registered owner of the Vehicle and the Service
            Provider, Garudayaan Logistics shall not be held responsible for
            such disputes. All such disputes must be met by the Service Provider
            and must indemnify Garudayaan Logistics in case of the loss of brand
            value, goodwill, loss of business, direct or indirect claims, claims
            from accident and/or offenses under the applicable laws, etc.
            situations during the term of this Agreement.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">RELATIONSHIP</h1>
        <p className="mt-2 font-medium text-base">
          The relationship between the parties is that of independent
          contractors and/or workers. Nothing in this Agreement shall be
          construed as creating a partnership, joint venture, employer-employee,
          agency, or other relationship between the Parties. The Service
          Provider shall have no right to exercise control over Garudayaan
          Logistics performance of its obligations under this Agreement and
          Garudayaan Logistics shall not have, and shall not represent that it
          has, any right or authority to bind the Service Provider, or to assume
          or create any obligation or responsibility, express or implied, on
          behalf of Service Provider.
        </p>
        <p className="mt-2 font-medium text-base">
          The Service Provider covenants that all Persons assigned by the
          Service Provider (not by way of transfer) to perform Services under
          this Agreement shall be employees or contractors of Service Provider
          or its affiliates and under no circumstances shall such persons be
          deemed to be employees of Garudayaan Logistics.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">SUB-CONTRACTING</h1>
        <p className="mt-2 font-medium text-base">
          The Service Provider shall not sub-contract any part of the Services
          under this Agreement to any third party without prior written consent
          of Garudayaan Logistics. Notwithstanding any such sub-contracting, if
          consented by Garudayaan Logistics, the Service Provider shall remain
          primarily liable and always obligated to Garudayaan Logistics
          hereunder.
        </p>
      </div>
      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">ASSIGNMENT</h1>
        <p className="mt-2 font-medium text-base">
          Subject to clauses of this Agreement, Garudayaan Logistics may assign
          this Agreement, in whole or in part, or any of its rights or
          obligations hereunder without the prior written consent of the Service
          Provider.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          INTELLECTUAL PROPERTY OWNERSHIP RIGHTS
        </h1>
        <p className="mt-2 font-medium text-base">
          Except as expressly set forth herein, as between the Parties,
          Garudayaan Logistics shall remain the owner of all intellectual
          property that it owns or controls as of the Effective Date or that it
          develops or acquires thereafter. Nothing in this Agreement will
          function to transfer any of either party's intellectual property
          rights to the other party and each party will retain exclusive
          interest in and ownership of its intellectual property developed
          before this Agreement or developed outside the scope of this
          Agreement.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">INDEMNIFICATION</h1>
        <p className="mt-2 font-medium text-base">
          The Service Provider shall indemnify Garudayaan Logistics from and
          against and in respect of any or all liabilities, losses, charges and
          expenses (including legal fees and costs on a full indemnity basis)
          claims in general and any provision which forms a part of this
          Agreement, demands, actions and proceedings, which Garudayaan
          Logistics may incur or sustain directly or indirectly from any breach
          by Service Provider of its obligations here under or any breach of
          Service Provider's representations and warranties, or by any reason,
          or in real action to the provision or proposed provision of the
          services by Service Provider and Service Provider shall forthwith pay
          all such sums forthwith on demand by Garudayaan Logistics in writing.
        </p>
        <p className="mt-2 font-medium text-base">
          Notwithstanding any other provisions of this Agreement, the Service
          Provider shall be liable to Garudayaan Logistics for indirect,
          special, or consequential damages arising out of or in connection with
          the provision of Services under this Agreement.
        </p>
        <p className="mt-2 font-medium text-base">
          The Service Provider agrees that in the event of any loss or damage to
          the Garudayaan Logistics, that is from the time they are entrusted to
          the Service Provider till they have delivered the requisite services
          to the Garudayaan Logistics, due to any reason whatsoever, except for
          Force Majeure, the Service Provider shall make good the loss, costs,
          charges, and expenses that the Garudayaan Logistics may suffer or
          incur in accordance with the terms of this Agreement.
        </p>

        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            Indemnify Garudayaan Logistics in accordance with the provisions of
            this Agreement. And
          </li>
          <li className="font-medium text-base">
            The Service Provider shall be solely liable for all offences and
            penalties relating to the Services.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">LIMITATION OF LIABILITY</h1>
        <p className="mt-2 font-medium text-base">
          To the extent permissible under applicable laws, Garudayaan Logistics
          is not liable for any civil, criminal, tortious, or any other
          liability, that may accrue as a consequence of the breach by the
          Service Provider (a) of the Applicable Laws in respect of the use of
          the Platform or providing the Services. (b) of the terms of the
          applicable licenses and permits that are issued by the transport
          authorities. (c) of the terms of these T&Cs. or (d) of the duty of
          care the Service Provider owes to the users of the Platform.
        </p>
        <p className="mt-2 font-medium text-base">
          Garudayaan Logistics maximum liability to the Service Provider in any
          event, which includes any failure by Garudayaan Logistics under this
          Agreement, shall be restricted and limited to a maximum amount of INR
          200 (Indian Rupees Two Hundred Only) in the aggregate during the Term.
        </p>
        <p className="mt-2 font-medium text-base">
          There will be no limitation of liability on the part of the Service
          Provider at any point of time due to any indemnity provisions as
          contained in this Agreement.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">FORCE MAJEURE</h1>
        <h1 className="mt-2 font-bold text-lg">Force Majeure Event:</h1>
        <p className="mt-2 font-medium text-base">
          A "Force Majeure Event" means any circumstance not within a Party's
          reasonable control including, without limitation:
        </p>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            Acts of God, flood, drought, earthquake or other natural disaster.
          </li>
          <li className="font-medium text-base">Epidemic or pandemic.</li>
          <li className="font-medium text-base">
            Terrorist attack, civil war, civil commotion or riots, war, threat
            of or preparation for war, armed conflict, imposition of sanctions,
            embargo, or breaking off of diplomatic relations.
          </li>
          <li className="font-medium text-base">
            Nuclear, chemical or biological contamination or sonic boom.
          </li>
          <li className="font-medium text-base">
            Any law or any action taken by a Governmental Authority or public
            authority, including without limitation imposing an export or import
            restriction, quota or prohibition, or failing to grant a necessary
            licence or consent.
          </li>
          <li className="font-medium text-base">
            Collapse of buildings, fire, explosion or accident.
          </li>
          <li className="font-medium text-base">
            Any labour or trade dispute, strikes, industrial action or lockouts
            (other than in each case by the Party seeking to rely on this
            clause, or companies in the same group as that Party).
          </li>
          <li className="font-medium text-base">
            Non-performance by suppliers or subcontractors (other than by
            companies in the same group as the Party seeking to rely on this
            clause). and
          </li>
          <li className="font-medium text-base">
            interruption or failure of utility service.
          </li>
        </ol>
      </div>
      <div>
        <h1>Effect of a Force Majeure Event:</h1>
        <p>
          Provided it has complied with this clause, if a Party is prevented,
          hindered, or delayed in or from performing any of its obligations
          under this Agreement by a Force Majeure Event ("Affected Party"), the
          Affected Party shall not be in breach of this Agreement or otherwise
          liable for any such failure or delay in the performance of such
          obligations. The time for performance of such obligations shall be
          extended accordingly. The corresponding obligations of the other Party
          will be suspended, and it's time for performance of such obligations
          extended, to the same extent as those of the Affected Party.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          Obligations of an Affected Party:
        </h1>
        <h1 className="mt-2 font-bold text-lg">The Affected Party shall:</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            as soon as reasonably practicable after the start of the Force
            Majeure Event but no later than 3 days from its start, notify the
            other Party in writing of the Force Majeure Event, the date on which
            it started, its likely or potential duration, and the effect of the
            Force Majeure Event on its ability to perform any of its obligations
            under the Agreement. and
          </li>
          <li className="font-medium text-base">
            use all reasonable endeavours to mitigate the effect of the Force
            Majeure Event on the performance of its obligations.
          </li>
        </ol>
        <h1 className="mt-2 font-bold text-lg">
          Right to terminate in case of a Force Majeure Event:
        </h1>
        <p className="font-medium text-base mt-2">
          Without prejudice to clause (Term and Termination), if the Force
          Majeure Event prevents, hinders, or delays the Affected Party's
          performance of its obligations for a continuous period of more than 3
          days, the Party not affected by the Force Majeure Event may terminate
          this Agreement by giving a written notice to the Affected Party.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">SURVIVAL</h1>
        <p className="font-medium text-base mt-2">
          Any terms and conditions that by their nature or otherwise reasonably
          should survive a cancellation or termination of this Agreement shall
          be deemed to survive the cancellation or termination of this
          Agreement. Such terms and conditions include, but are not limited to,
          Term and Termination, Representations and Warranties, Relationship,
          Intellectual Property Ownership Rights, Indemnification, Governing
          Law, Dispute Resolution, Severability and Disclaimer.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">GOVERNING LAW</h1>
        <p className="font-medium text-base mt-2">
          This Agreement shall be governed by, and construed in accordance with,
          the laws of India, without regard to the principles of conflict of
          laws.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">DISPUTE RESOLUTION</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            Garudayaan Logistics hereby disclaims and shall disclaim all
            representations and warranties to the customer, of any kind, whether
            express or implied as to condition, suitability, quality,
            merchantability, Vehicle and all ancillary matters related to it and
            fitness for any purposes in respect of any and all the Vehicles of
            the Service Provider that are used by the customer as a part of the
            service offered by Garudayaan Logistics.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics does not warrant that You will be able to use
            Garudayaan Logistics Platform and/ or will be able to provide the
            Services at all times or locations on Garudayaan Logistics Platform
            or that Garudayaan Logistics Platform and the Platform Services will
            be uninterrupted or error-free or that the defects will be capable
            of being corrected by the Garudayaan Logistics in a timely fashion.
            The Platform Services, Platform, Device, the output generated there
            from, and all other technology developed by Garudayaan Logistics are
            provided to you on an "AS IS" and "AS AVAILABLE" basis and
            Garudayaan Logistics specifically disclaims all warranties and
            indemnities, express, implied or statutory, including without
            limitation any warranty of merchantability, fitness for a particular
            purpose, accuracy, completeness, any assurance of minimum business
            guarantee with respect to any Fees or any other warranty arising
            from the course of performance or course of dealing.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics shall not be responsible or liable for any loss
            or damage, how so ever caused or suffered by the Service Provider
            arising out of the use of the service offered by Garudayaan
            Logistics or due to the failure of Garudayaan Logistics to provide
            services to the consignor/consignee for any reason whatsoever
            including but not limited to any customer's noncompliance with the
            services offered by Garudayaan Logistics, which includes, but is not
            limited to any incorrectly placed voice instructions, malfunction,
            partial or total failure of any network terminal, data processing
            system, computer tele transmission or telecommunication system or
            other circumstances whether or not beyond the control of Garudayaan
            Logistics or any person or any organization involved in the above
            mentioned systems. The Service provider shall also be liable to
            Garudayaan Logistics for any loss caused to Garudayaan Logistics due
            to the negligence of the Service Provider and/or his appointed
            operators/contractors and or any unlawful act or omission in the
            performance of the services. Without prejudice to the above,
            Garudayaan Logistics shall not be liable for any direct or indirect
            loss or damage, which may be suffered by the Service Provider as a
            result of any failure by a consignor/consignee to show up within any
            stipulated time even if Garudayaan Logistics has agreed to such
            timing or even if the customer has a devised Garudayaan Logistics of
            the possibility that he/she may not show up within the stipulated
            time. and
          </li>
          <li className="font-medium text-base">
            The Platform makes available general third-party information and
            other data from external sources ("Third Party Content"). The
            provision of Third-Party Content is for general informational
            purposes only. The Service Provider acknowledges that the
            Third-Party Content provided is obtained from sources believed to be
            reliable. Garudayaan Logistics does not provide any guarantee with
            respect to any Third-Party Content and Garudayaan Logistics shall
            not be held liable for any loss suffered by the Service Provider
            based on the reliance placed on or use of Third-Party Content.
            Further, to the extent that such Third-Party Content is infringing
            upon some other party's intellectual property rights or proprietary
            rights, Garudayaan Logistics shall not be held liable for the same
            at any instances.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">SEVERABILITY</h1>
        <p className="font-medium text-base mt-2">
          If any part or any provision of this Agreement is or becomes illegal,
          invalid, or unenforceable, that part or provision shall be ineffective
          to the extent of such invalidity or unenforceability only, without in
          any way affecting the validity or enforceability of the remaining
          parts of said provision or the remaining provisions of this Agreement.
          The parties hereby agree to attempt to substitute any invalid or
          unenforceable provision with a valid or enforceable provision, which
          achieves to the greatest extent possible the economic, legal, and
          commercial objectives of the invalid or unenforceable provision.
        </p>
      </div>
      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">ENTIRE AGREEMENT</h1>
        <p className="font-medium text-base mt-2">
          This Agreement, together with the schedules and other documents
          specifically attached or referred to herein, constitutes the entire
          agreement between the parties with respect to the subject matter
          hereof, and supersedes all prior understandings, promises,
          representations, agreements, and negotiations between the parties,
          oral or written. No amendment or modification of this Agreement shall
          be binding unless made in writing and duly signed by both parties.
        </p>
      </div>
      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">WAIVER</h1>
        <p className="font-medium text-base mt-2">
          Each party agrees that any delay or omission on the part of the other
          party to exercise any right, power or remedy under this Agreement will
          not automatically operate as a waiver of such right, power or remedy
          or any other right, power or remedy and no waiver will be effective
          unless it is in writing and delivered to the other party. Further, the
          waiver or the single or partial exercise of any right, power, or
          remedy by either party hereunder on one occasion shall not be
          construed as a bar to a waiver of any successive or other right, power
          or remedy on any other occasion.
        </p>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">DISCLAIMER</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            Garudayaan Logistics hereby disclaims and shall disclaim all
            representations and warranties to the customer, of any kind, whether
            express or implied as to condition, suitability, quality,
            merchantability, Vehicle and all ancillary matters related to it and
            fitness for any purposes in respect of any and all the Vehicles of
            the Service Provider that are used by the customer as a part of the
            service offered by Garudayaan Logistics.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics does not warrant that You will be able to use
            Garudayaan Logistics Platform and/ or will be able to provide the
            Services at all times or locations on Garudayaan Logistics Platform
            or that Garudayaan Logistics Platform and the Platform Services will
            be uninterrupted or error-free or that the defects will be capable
            of being corrected by the Garudayaan Logistics in a timely fashion.
            The Platform Services, Platform, Device, the output generated there
            from, and all other technology developed by Garudayaan Logistics are
            provided to you on an "AS IS" and "AS AVAILABLE" basis and
            Garudayaan Logistics specifically disclaims all warranties and
            indemnities, express, implied or statutory, including without
            limitation any warranty of merchantability, fitness for a particular
            purpose, accuracy, completeness, any assurance of minimum business
            guarantee with respect to any Fees or any other warranty arising
            from the course of performance or course of dealing.
          </li>
          <li className="font-medium text-base">
            Garudayaan Logistics shall not be responsible or liable for any loss
            or damage, how so ever caused or suffered by the Service Provider
            arising out of the use of the service offered by Garudayaan
            Logistics or due to the failure of Garudayaan Logistics to provide
            services to the consignor/consignee for any reason whatsoever
            including but not limited to any customer's noncompliance with the
            services offered by Garudayaan Logistics, which includes, but is not
            limited to any incorrectly placed voice instructions, malfunction,
            partial or total failure of any network terminal, data processing
            system, computer tele transmission or telecommunication system or
            other circumstances whether or not beyond the control of Garudayaan
            Logistics or any person or any organization involved in the above
            mentioned systems. The Service provider shall also be liable to
            Garudayaan Logistics for any loss caused to Garudayaan Logistics due
            to the negligence of the Service Provider and/or his appointed
            operators/contractors and or any unlawful act or omission in the
            performance of the services. Without prejudice to the above,
            Garudayaan Logistics shall not be liable for any direct or indirect
            loss or damage, which may be suffered by the Service Provider as a
            result of any failure by a consignor/consignee to show up within any
            stipulated time even if Garudayaan Logistics has agreed to such
            timing or even if the customer has a devised Garudayaan Logistics of
            the possibility that he/she may not show up within the stipulated
            time. And
          </li>
          <li className="font-medium text-base">
            The Platform makes available general third-party information and
            other data from external sources ("Third Party Content"). The
            provision of Third-Party Content is for general informational
            purposes only. The Service Provider acknowledges that the
            Third-Party Content provided is obtained from sources believed to be
            reliable. Garudayaan Logistics does not provide any guarantee with
            respect to any Third-Party Content and Garudayaan Logistics shall
            not be held liable for any loss suffered by the Service Provider
            based on the reliance placed on or use of Third-Party Content.
            Further, to the extent that such Third-Party Content is infringing
            upon some other party's intellectual property rights or proprietary
            rights, Garudayaan Logistics shall not be held liable for the same
            at any instances.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">MISCELLANEOUS</h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            This Agreement may be executed and delivered in two counterparts,
            each of which shall be deemed as an original, or by facsimile or
            other electronic copies, such as by email. The parties agree that
            such facsimile or electronic execution and shall have the same force
            and effect as of an original document with original signatures.
          </li>
          <li className="font-medium text-base">
            All remedies of either party under this Agreement whether provided
            herein or conferred under statute, civil law, common law, custom or
            trade usages, are cumulative and not alternative and may be enforced
            successively or concurrently.
          </li>
          <li className="font-medium text-base">
            Each party shall bear its own costs in connection with its
            obligations under this Agreement. Further, all charges and expenses
            including stamp duty or otherwise in relation these presents shall
            be borne and paid by the Service Provider. The Service Provider
            shall bear and pay their own advocates' fees.
          </li>
          <li className="font-medium text-base">
            Each party will act in good faith in the performance of its
            respective responsibilities under this Agreement and will not
            unreasonably delay, condition, or withhold the giving of any
            consent, decision or approval that is either requested or reasonably
            required by the other party to perform its responsibilities.
          </li>
          <li className="font-medium text-base">
            No variation of this Agreement shall be effective unless it is in
            writing and signed by the Parties (or their authorized
            representatives). The Parties agree that that the Parties may entire
            into one or more written addendums from time to time to supplement,
            modify, or alter the terms of this Agreement.
          </li>
          <li className="font-medium text-base">
            Any violation of any provision hereof, any incomplete or mistaken
            performance of any obligation provided hereunder, any
            misrepresentation made hereunder, any material nondisclosure or
            omission of any material fact, or any failure to perform any
            covenants provided hereunder by any Party shall constitute a breach
            of this Agreement. The breaching Party shall be liable for any such
            breach pursuant to the applicable laws. On breach of covenants of
            agreement by defaulting party, consequently other party of the
            agreement will be discharged from all its obligations and
            liabilities from the moment breach is committed till the time breach
            is corrected.
          </li>
          <li className="font-medium text-base">
            This Agreement shall be binding and inure to the benefit of the
            Parties and their respective legal representatives, administrators,
            executors, and permitted assigns.
          </li>
          <li className="font-medium text-base">
            For so long as this Agreement is subsisting and for a period of 12
            (twelve) months thereafter, the Service Provider hereby agrees to
            not to solicit / poach/approach/hire/take on contract/employ,
            directly or indirectly, any driver partner(s) / contractors /
            Vehicles operated by / engaged with / associated/registered with
            Garudayaan Logistics, either fixed and/or adhoc, for its
            requirements.
          </li>
          <li className="font-semibold text-base">
            RPO and Non-Disparagement:
          </li>
          <ol className="list-decimal pl-5 space-y-3 mt-3">
            <li className="font-medium text-base">
              The Service Provider shall provide the Services with the due care,
              skill and diligence of a reasonable prudent operator ("RPO") and
              shall procure that any person performing under this Agreement
              shall provide the Services to the best of his/her ability and in
              accordance with the standards of an RPO. For the purposes of this
              Agreement, a "RPO" means a person seeking in good faith to perform
              its contractual obligations and in so doing and in the general
              conduct of its undertaking, exercises that degree of skill,
              diligence, prudence and foresight which would reasonably and
              ordinarily be expected from a skilled and experienced operator
              complying with all Applicable Laws and statutory obligations,
              engaged in same type of undertaking, under the same or similar
              circumstances and conditions and any reference to the standards of
              a RPO will be construed accordingly.
            </li>
            <li className="font-medium text-base">
              Specifically, the Service Provider shall ensure that the Services
              are provided in compliance with the Motor Vehicles Act, 1988,
              Motor Transport Workers Act, 1961 or any Rules made thereunder,
              Carriage by Road Act 2007 and Carriage by Road Rule 2011 and in
              strict compliance with the provisions of any Applicable Laws along
              with this Agreement.
            </li>
            <li className="font-medium text-base">
              The Service Provider agrees to provide the Services in a manner so
              as to advance the business interests and goodwill of Garudayaan
              Logistics. Specifically, the Service Provider agrees not to, and
              shall ensure that its drivers do not, (a) form any union, trusts,
              association, society or any other type of entity. or (b) hold
              meetings, conference or any other form of gathering of 5 or more,
              to discuss this Agreement, the Services and payments provided
              hereunder and/or the general affairs of Garudayaan Logistics
            </li>
            <li className="font-medium text-base">
              The Service Provider shall not directly or indirectly engage in
              any conduct averse to the best interests of Garudayaan Logistics.
              The Service Provider shall not, and shall ensure that its drivers
              and other employees do not disparage or make any negative comments
              (either oral or in writing) about, Garudayaan Logistics or any of
              its officers, employees, directors or other representatives.
            </li>
            <li className="font-medium text-base">
              The Service Provider shall be directly liable for on all cases as
              may be initiated through the Vehicle during the engagement hours
              while providing the Services. and
            </li>
            <li className="font-medium text-base">
              Notwithstanding to anything contained anywhere in this Agreement,
              breach of this clause by the Service Provider shall entitle
              Garudayaan Logistics to terminate this Agreement and Garudayaan
              Logistics shall be entitled for all indemnification from the
              Service Provider, as per this Agreement.
            </li>
          </ol>
          <li className="font-semibold text-base">
            Branding and Advertising on the Vehicles:
          </li>
          <ol className="list-decimal pl-5 space-y-3 mt-3">
            <li className="font-medium text-base">
              The Service Provider and/or the driver(s) will allow Garudayaan
              Logistics or its customers to advertise / brand on its Vehicle(s)
              (including but not limited to any branding / advertising, if at
              any point of time, as requested by Garudayaan Logistics on such
              terms and conditions as may be specified by Garudayaan Logistics
              and if accepted by the Service Provider and/or its driver(s), from
              time to time).
            </li>
            <li className="font-medium text-base">
              The Service Provider and/or the driver(s) shall comply with all
              the applicable law(s) / regulations for branding / advertising on
              its Vehicle(s) as defined by the state/central transport
              corporation/authority. The Service Provider and/or the driver(s)
              shall be solely responsible for compliance with the laws /
              regulations of the state/central transport corporation/authority
              (including the regional transport offices where the Vehicle got
              registered, local bodies, regulatory agencies (local / state /
              central) i.e. ensuring to get the Garudayaan Logistics branding
              approval in their Vehicle from the respective regional transport
              offices/state transport authority, where the Vehicle got
              registered within the prescribed time period. The Service Provider
              and/or the driver(s) shall be solely responsible for any
              liability, loss, action and / or penalty arising out of any
              non-compliance with the applicable laws in relation to such
              branding / advertising and Garudayaan Logistics will not be
              responsible for such liability in any manner.
            </li>
            <li className="font-medium text-base">
              The Service Provider and/or the driver(s) may not opt-out of any
              branding / advertising program of Garudayaan Logistics or its
              customers (as applicable) and stop branding / advertising on its
              Vehicle(s) as per its convenience, provided however that the
              Service Provider and/or its driver(s) shall be solely liable and
              accountable for any responsibility(ies) / liability(ies) /
              loss(es) / action(s) / penalty(ies) by any government, regional
              transport office(s) / police/ local / regulatory body(ies) arising
              during the time they opted for such branding/advertising activity
              any such-opt out from branding / advertising program of Garudayaan
              Logistics or stoppage of branding / advertising their Vehicles, by
              the Service Provider and/or the driver(s), shall not absolve them
              from. And
            </li>
            <li className="font-medium text-base">
              Non-adherence of this clause by the Service Provider and/or the
              driver(s) shall be deemed to be a breach of this Agreement by the
              Service Provider and/or the driver(s) and Garudayaan Logistics may
              terminate this Agreement without any notice and further, the
              Service Provider and/or the driver(s) agrees to indemnify
              Garudayaan Logistics from and against and in respect of any or all
              liabilities, losses, charges and expenses (including legal fees
              and costs on a full indemnity basis), claims, demands, actions and
              proceedings, which Garudayaan Logistics may incur or sustain
              directly or indirectly from any breach by Service Provider and/or
              the driver(s) of its obligations here under or any breach of
              Service Provider's and/or the driver(s's representations and
              warranties, or by any reason, or in real action to the provision
              or proposed provision of the services by Service Provider and/or
              its driver(s) and Service Provider and/or the driver(s) shall
              forthwith pay all such sums forthwith on demand by Garudayaan
              Logistics in writing.
            </li>
          </ol>
          <li className="font-medium text-base">
            Garudayaan Logistics retains the right to share the information
            provided by You with any financial lending institution(s) and/or
            insurance broker entities if you agree to the same, if it is proved
            within reason that you have availed a service from them. You forego
            the right to claim any damages from Garudayaan Logistics in the
            event any such financial facility has been availed by you. You also
            agree that Garudayaan Logistics will not be liable for any damages
            arising as a result of such disclosure of your information.
            Garudayaan Logistics retains the right to withhold pending Fees and
            terminate you on an immediate basis in such cases.
          </li>
          <li className="font-medium text-base">
            The Service Provider hereby acknowledges and agrees to the use of
            his/her photographs by Garudayaan Logistics for certain purposes,
            which may include usage of the Service Provider photographs in
            Garudayaan Logistics internal communications and presentations,
            training materials, marketing and advertisement activities on
            various platforms, including without limitation, online
            advertisement, social media and offline advertisement. The Service
            Provider hereby consents to sharing of his/her photographs by
            Garudayaan Logistics with third parties for the aforesaid purposes.
            The Service Provider further agrees to the Privacy Policy of
            Garudayaan Logistics as available on the Platform including website
            of Garudayaan Logistics.com
          </li>
          <li className="font-medium text-base">
            The Parties agree that the electronic signature of a party to this
            Agreement shall be as valid as an original signature of such party
            and shall be effective to bind such party to this Agreement. The
            parties agree that the electronically signed document (including
            this Agreement) shall be deemed (i) to be "written" or "in writing,"
            (ii) to have been signed and (iii) to constitute a record
            established and maintained in the ordinary course of business and an
            original written record when printed from electronic files. Such
            paper copies or "printouts," if introduced as evidence in any
            judicial, arbitral, mediation or administrative proceeding, will be
            admissible between the parties to the same extent as physical signed
            document.
          </li>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          SERVICE PROVIDER'S TERMS AND CONDITIONS FOR VEHICLE MAKE OF 2 WHEELERS
        </h1>
        <ol className="list-decimal pl-10 space-y-3 mt-3">
          <li className="font-medium text-base">
            The Service Provider acknowledges and agrees that the Vehicle with
            which he/she chooses to provide Services shall be
          </li>
          <ol className="list-decimal pl-5 space-y-3 mt-3">
            <li className="font-medium text-base">
              To operate in compliance with all applicable laws.
            </li>
            <li className="font-medium text-base">
              Properly registered, insured and licensed in accordance with law
              to operate as a passenger transportation Vehicle, a shared
              mobility Vehicle and/or Vehicle to transport/deliver Services.
            </li>
            <li className="font-medium text-base">
              Must wear helmet while driving the Vehicle.
            </li>
            <li className="font-medium text-base">
              To keep the Vehicle engine turned while providing the Services
              except upon reaching the origin and/or destination.
            </li>
            <li className="font-medium text-base">
              To maintain proper speed limit as prescribed by the Government
              Authority.
            </li>
            <li className="font-medium text-base">
              Suitable for performing the Services. and
            </li>
            <li className="font-medium text-base">
              Maintained the Vehicle in good operating condition, consistent
              with industry safety and maintenance standards for a Vehicle of
              its kind and any additional standards or requirements in the
              applicable Branch Location, and in a clean and sanitary condition.
            </li>
          </ol>
          <li className="font-medium text-base ">
            The Service Provider may or may not have ownership as to the right,
            title and interest on the Vehicle and is responsible and liable for
            all loss or damages as to any matter arising from the vehicle
            including the claims, demands, charges, expenses, show cause,
            detention, litigation cases, actions from Government Authority and
            but not limited to objections, etc. as may be raised by a third
            party, family or related members, appropriate authorities, police
            functionary, etc. and shall keep Garudayaan Logistics. <br />
            indemnified from all such claims from time to time during the course
            during the term of this Agreement. The Service Provider shall also
            provide Garudayaan Logistics. <br /> the requisite documents as
            prescribed under Annexure A to furnish the Vehicle owner details.
          </li>
          <li className="font-medium text-base ">
            Service Provider makes himself/herself available to undertake
            Services as and when a request for the same is placed by User
            through Garudayaan Logistics.com <br /> Platform.
          </li>
          <li className="font-medium text-base ">
            If required, the Service Provider may use his personal Vehicle or
            acquire a Vehicle from a third party (âœService Provider Vehicle")
            for providing the Services. No Vehicle of any kind shall be provided
            by Garudayaan Logistics under any circumstances and Garudayaan
            Logistics will not be liable to settle any claims or pay any amounts
            in regard to any accidental claims, insurance claims, tolls and all
            expenses with regard to the acquisition and maintenance of the
            Service Provider Vehicle are to be borne by the Service Provider
            solely.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall ensure that the Service Provider Vehicle
            is well maintained and in good condition so that there are no delays
            in rendering Services.
          </li>
          <li className="font-medium text-base ">
            All expenses incurred in maintaining, running and riding the Service
            Provider Vehicle shall be borne exclusively by the Service Provider.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall hold and possess a valid driving license
            and a valid registration number for the Service Provider Vehicle, if
            required under the Applicable Law for the Vehicle used by the
            Service Provider for providing Services, which are up to date and in
            subsistence throughout the Term of these Service Provider T&C.
            Copies of the driving license as well as the registration
            certificate of the Service Provider Vehicle, including any other
            Service Provider Information, shall be handed to Garudayaan
            Logistics before commencing Services or at any other time deemed
            appropriate by Garudayaan Logistics.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall have a valid and adequate insurance
            coverage to the Service Provider Vehicle. Service Provider shall
            ensure that the insurance is valid, up to date and in subsistence
            throughout the Term of these Service Provider T&C. A copy of the
            insurance policy shall be given by the Service Provider to
            Garudayaan Logistics. The Service Provider further confirms that all
            premium payments towards the insurance policy shall be paid
            exclusively by the Service Provider. Under no circumstances shall
            Garudayaan Logistics be liable to make any payment with respect to
            such insurance.
          </li>

          <li className="font-medium text-base ">
            During the course of undertaking Services, the Service Provider
            shall conduct himself with honesty, discipline and in accordance
            with the policies and instructions of Garudayaan Logistics, whether
            presently in force or adopted in the future, including but not
            limited to safety, driving rules, etc. The Service Provider shall
            comply with the Applicable Laws as required to be complied with by
            any person driving Vehicles.
          </li>
          <li className="font-medium text-base ">
            Service Provider shall not commit any fraud while providing Services
            or otherwise commit any act or omission, to gain any undue
            advantage. Service Provider agrees and acknowledges that in case
            Garudayaan Logistics believes that the Service Provider has
            committed any of the foregoing while undertaking Services,
            Garudayaan Logistics shall, in addition to its right to terminate
            the Agreement and these Service Provider T&C, in its sole discretion
            reserve the right to (a) disable the Service Provider from
            undertaking Services through Garudayaan Logistics Platform for such
            time as it deems fit and /or (b) deduct the undue gain made by the
            Service Provider through the fraudulent orders from the pay out
            including incentives/Fees thereof and/or the Security Deposit, if
            any. This right to withhold pay out including incentives/Fees
            thereof may also be exercised by Garudayaan Logistics in the event
            service parameter guidelines, as prescribed by Garudayaan Logistics
            from time to time, are not met.
          </li>
          <li className="font-medium text-base ">
            At no time whatsoever shall the Service Provider tamper, damage,
            open or do anything to the consignments of the Users that he/she is
            not specifically permitted to do. In case Garudayaan Logistics
            suffers any loss due to the Service Provider tampering, damaging or
            opening the packaging of consignments of the Users, Garudayaan
            Logistics shall have the right to recover any loss, if any, from the
            payments required to be made by Garudayaan Logistics to the Service
            Provider under the Agreement or these Service Provider T&C.
          </li>
          <li className="font-medium text-base ">
            The Service Provider acknowledges that the goodwill and reputation
            of Garudayaan Logistics is at stake with how effectively and
            efficiently the Service Provider render Services pursuant to these
            Service Provider T&C. Accordingly, the Service Provider shall not do
            any act that adversely affects Garudayaan Logistics and undertakes
            to be in compliance with Applicable Law at all times and protect the
            brand image, business reputation or any other asset/property of
            Garudayaan Logistics.
          </li>
          <li className="font-medium text-base ">
            While logged-in Garudayaan Logistics Platform, the Service Provider
            shall not engage in any illegal activity or perform any actions that
            are contrary to Applicable Law.
          </li>
          <li className="font-medium text-base ">
            All Confidential Information procured shall at all times be kept
            confidential and used only for the limited permitted purposes of
            rendering Services.
          </li>
          <li className="font-medium text-base ">
            The Service Provider is not entitled to claim reimbursement of
            hospitalization/ hospital bills that may be incurred by the Service
            Provider while rendering Services or incurred by his family members
            for any unfortunate accidents or severe illness, during the
            subsistence of Agreement and these Service Provider T&C.
          </li>
          <li className="font-semibold text-base">
            The Service Provider shall not resort to the below mentioned acts/
            commission/ omission of events:
          </li>
          <ol className="mt-3 space-y-3 list-decimal pl-5">
            <li className="font-medium text-base ">
              Any violation or breach of any term of these T&Cs.
            </li>
            <li className="font-medium text-base ">
              If the Service Provider, in the opinion of Garudayaan Logistics,
              misuses the Portal.
            </li>
            <li className="font-medium text-base ">
              failure to verify or authenticate Service Provider Information.
            </li>
            <li className="font-medium text-base ">
              Misbehaviour, rude behaviour with the staff of Garudayaan
              Logistics, customers or any other persons associated with
              Garudayaan Logistics.
            </li>
            <li className="font-medium text-base ">
              Any acts involving criminal offence punishable under law,
              including physical assault, threatening any of the staff of
              Garudayaan Logistics, person associated with Garudayaan Logistics
              and any other persons.
            </li>
            <li className="font-medium text-base ">
              Concealment of fact/material information while entering into a
              contract with Garudayaan Logistics.
            </li>
            <li className="font-medium text-base ">
              Poor & irregular for work, meetings and failure to abide by the
              rules/terms of the contract.
            </li>
            <li className="font-medium text-base ">
              Drunk while on duty and drunken behaviour.
            </li>
            <li className="font-medium text-base ">
              Commission of fraud/ misappropriation/embezzlement for undue
              monetary gain, which is against the interest of Garudayaan
              Logistics.
            </li>
            <li className="font-medium text-base ">
              Negligence in performing the duty, causing damage of moveable and
              immoveable assets of Garudayaan Logistics, its employees,
              customers.
            </li>

            <li className="font-medium text-base ">
              Indulging in spreading content through digital media, social
              networking sites, or any other form, which could be detrimental to
              Garudayaan Logistics brand and its image. Indulging in acts such
              as creating ruckus/ strike/ or any activity against Garudayaan
              Logistics, which could be detrimental to Garudayaan Logistics
              brand and its image.
            </li>
            <li className="font-medium text-base ">
              Indulging in acts such as creating ruckus/ strike/ or any activity
              against Garudayaan Logistics, which could be detrimental to
              Garudayaan Logistics brand and its image.
            </li>
            <li className="font-medium text-base ">
              Indulging in unauthorized disclosure of confidential information
              of Garudayaan Logistics to external agency, person, Garudayaan
              Logistics or organization.
            </li>
            <li className="font-medium text-base ">
              Misuse of assets provided by Garudayaan Logistics, which could be
              detrimental to the interest of Garudayaan Logistics brand and its
              image.
            </li>
            <li className="font-medium text-base ">
              Absconding for more than 4 hours with any asset, item, money or
              any other valuable item belonging to Garudayaan Logistics, its
              employees, customers or other staff member(s).
            </li>
            <li className="font-medium text-base ">
              Failure to abide by any of the rules and guidelines given by
              Garudayaan Logistics as part of service quality standards and
              principles and/or Code of Conduct. or
            </li>
            <li className="font-medium text-base ">
              In case the background check, whether wholly or partially, is
              found negative at any point of time during the term of these T&Cs.
            </li>
          </ol>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="mt-2 font-bold text-lg">
          SERVICE PROVIDER'S TERMS AND CONDITIONS FOR VEHICLE MAKE OF 3 WHEELERS
          AND 4 WHEELERS
        </h1>
        <ol className="mt-3 space-y-3 list-decimal pl-5">
          <li className="font-semibold text-base ">
            The Service Provider acknowledges and agrees that the Vehicle with
            which he/she chooses to provide Services shall be:
          </li>
          <ol className="mt-3 space-y-3 list-decimal pl-5">
            <li className="font-medium text-base ">
              to operate in compliance with all applicable laws.
            </li>
            <li className="font-medium text-base ">
              properly registered, insured and licensed in accordance with law
              to operate as a passenger transportation Vehicle, a shared
              mobility Vehicle and/or Vehicle to transport/deliver Services.
            </li>
            <li className="font-medium text-base ">
              must apply the seat belt while driving the Vehicle.
            </li>
            <li className="font-medium text-base ">
              to keep the pollution certifications in place.
            </li>
            <li className="font-medium text-base ">
              to maintain proper speed limit as prescribed by the Government
              Authority.
            </li>
            <li className="font-medium text-base ">
              to check and maintain that before the departure of the locations,
              the Vehicle is under the responsibility of the Service Provider
              deployed therein at the time of loading of the consignment to
              check and verify that all such consignments are loaded in
              absolutely sealed containers/packages. Post verification of the
              same, they should affix their sign and seal as well as a token of
              verification. If any loss and/or damage arises due to any such
              seal/lock cutting incident, then the Service Provider shall be
              immediately liable to report such incident at the nearest police
              station by way of lodging a First Information Report and thereby
              immediately take pictures/photographs of the same and report such
              incident to the nearest office of the Company and shall thereby
              handover the copy of the FIR together with the photographs. In
              case of any deviation and/or contravention/non-compliance, the
              Service Provider shall be solely responsible and liable to make
              good all the consequential loss/damage arising there from which is
              inclusive but not limited to payment of incidental expenses
              relating to the same, viz. Police Station Expenses for the lodging
              of the FIR, travelling expenses of the representative of the
              Garudayaan Logistics to manage such incident.
            </li>
            <li className="font-medium text-base ">
              suitable for performing the Services. and
            </li>
            <li className="font-medium text-base ">
              maintained the Vehicle in good operating condition, consistent
              with industry safety and maintenance standards for a Vehicle of
              its kind and any additional standards or requirements in the
              applicable Branch Location, and in a clean and sanitary condition.
            </li>
            <li className="font-medium text-base ">
              The Service Provider may or may not have ownership as to the
              right, title and interest on the Vehicle and is responsible and
              liable for all loss or damages as to any matter arising from the
              vehicle including the claims, demands, charges, expenses, show
              cause, detention, litigation cases, actions from Government
              Authority and but not limited to objections, etc. as may be raised
              by a third party, family or related members, appropriate
              authorities, police functionary, etc. and shall keep Garudayaan
              Logistics indemnified from all such claims from time to time
              during the course during the term of this Agreement. The Service
              Provider shall also provide Garudayaan Logistics the requisite
              documents as prescribed under Annexure A to furnish the Vehicle
              owner details.
            </li>
            <li className="font-medium text-base ">
              Service Provider makes himself/herself available to undertake
              Services as and when a request for the same is placed by User
              through Garudayaan Logistics Platform.
            </li>
          </ol>
          <li className="font-medium text-base ">
            The Service Provider may or may not have ownership as to the right,
            title and interest on the Vehicle and is responsible and liable for
            all loss or damages as to any matter arising from the vehicle
            including the claims, demands, charges, expenses, show cause,
            detention, litigation cases, actions from Government Authority and
            but not limited to objections, etc. as may be raised by a third
            party, family or related members, appropriate authorities, police
            functionary, etc. and shall keep Garudayaan Logistics indemnified
            from all such claims from time to time during the course during the
            term of this Agreement. The Service Provider shall also provide
            Garudayaan Logistics the requisite documents as prescribed under
            Annexure A to furnish the Vehicle owner details.
          </li>
          <li className="font-medium text-base ">
            Service Provider makes himself/herself available to undertake
            Services as and when a request for the same is placed by User
            through Garudayaan Logistics Platform.
          </li>
          <li className="font-medium text-base ">
            If required, the Service Provider may use his personal Vehicle or
            acquire a Vehicle from a third party (âœService Provider Vehicle")
            for providing the Services. No Vehicle of any kind shall be provided
            by Garudayaan Logistics under any circumstances and Garudayaan
            Logistics will not be liable to settle any claims or pay any amounts
            in regard to any accidental claims, insurance claims, tolls and all
            expenses with regard to the acquisition and maintenance of the
            Service Provider Vehicle are to be borne by the Service Provider
            solely.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall ensure that the Service Provider Vehicle
            is well maintained and in good condition so that there are no delays
            in rendering Services.
          </li>
          <li className="font-medium text-base ">
            All expenses incurred in maintaining, running and riding the Service
            Provider Vehicle shall be borne exclusively by the Service Provider.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall hold and possess a valid driving license
            and a valid registration number for the Service Provider Vehicle, if
            required under the Applicable Law for the Vehicle used by the
            Service Provider for providing Services, which are up to date and in
            subsistence throughout the Term of these Service Provider T&C.
            Copies of the driving license as well as the registration
            certificate of the Service Provider Vehicle, including any other
            Service Provider Information, shall be handed to Garudayaan
            Logistics before commencing Services or at any other time deemed
            appropriate by Garudayaan Logistics.
          </li>
          <li className="font-medium text-base ">
            The Service Provider shall have a valid and adequate insurance
            coverage to the Service Provider Vehicle. Service Provider shall
            ensure that the insurance is valid, up to date and in subsistence
            throughout the Term of these Service Provider T&C. A copy of the
            insurance policy shall be given by the Service Provider to
            Garudayaan Logistics. The Service Provider further confirms that all
            premium payments towards the insurance policy shall be paid
            exclusively by the Service Provider. Under no circumstances shall
            Garudayaan Logistics be liable to make any payment with respect to
            such insurance.
          </li>
          <li className="font-medium text-base ">
            During the course of undertaking Services, the Service Provider
            shall conduct himself with honesty, discipline and in accordance
            with the policies and instructions of Garudayaan Logistics, whether
            presently in force or adopted in the future, including but not
            limited to safety, driving rules, etc. The Service Provider shall
            comply with the Applicable Laws as required to be complied with by
            any person driving Vehicles.
          </li>
          <li className="font-medium text-base ">
            Service Provider shall not commit any fraud while providing Services
            or otherwise commit any act or omission, to gain any undue
            advantage. Service Provider agrees and acknowledges that in case
            Garudayaan Logistics believes that the Service Provider has
            committed any of the foregoing while undertaking Services,
            Garudayaan Logistics shall, in addition to its right to terminate
            the Agreement and these Service Provider T&C, in its sole discretion
            reserve the right to (a) disable the Service Provider from
            undertaking Services through Garudayaan Logistics Platform for such
            time as it deems fit and /or (b) deduct the undue gain made by the
            Service Provider through the fraudulent orders from the pay out
            including incentives/Fees thereof and/or the Security Deposit, if
            any. This right to withhold pay out including incentives/Fees
            thereof may also be exercised by Garudayaan Logistics in the event
            service parameter guidelines, as prescribed by Garudayaan Logistics
            from time to time, are not met.
          </li>
          <li className="font-medium text-base ">
            At no time whatsoever shall the Service Provider tamper, damage,
            open or do anything to the consignments of the Users that he/she is
            not specifically permitted to do. In case Garudayaan Logistics
            suffers any loss due to the Service Provider tampering, damaging or
            opening the packaging of consignments of the Users, Garudayaan
            Logistics shall have the right to recover any loss, if any, from the
            payments required to be made by Garudayaan Logistics to the Service
            Provider under the Agreement or these Service Provider T&C.
          </li>
          <li className="font-medium text-base ">
            The Service Provider acknowledges that the goodwill and reputation
            of Garudayaan Logistics is at stake with how effectively and
            efficiently the Service Provider render Services pursuant to these
            Service Provider T&C. Accordingly, the Service Provider shall not do
            any act that adversely affects Garudayaan Logistics and undertakes
            to be in compliance with Applicable Law at all times and protect the
            brand image, business reputation or any other asset/property of
            Garudayaan Logistics.
          </li>
          <li className="font-medium text-base ">
            While logged-in Garudayaan Logistics Platform, the Service Provider
            shall not engage in any illegal activity or perform any actions that
            are contrary to Applicable Law.
          </li>
          <li className="font-medium text-base ">
            All Confidential Information procured shall at all times be kept
            confidential and used only for the limited permitted purposes of
            rendering Services.
          </li>
          <li className="font-medium text-base ">
            The Service Provider is not entitled to claim reimbursement of
            hospitalization/ hospital bills that may be incurred by the Service
            Provider while rendering Services or incurred by his family members
            for any unfortunate accidents or severe illness, during the
            subsistence of Agreement and these Service Provider T&C.
          </li>
          <li className="font-semibold text-base">
            The Service Provider shall not resort to the below mentioned acts/
            commission/ omission of events:
          </li>
          <ol className="mt-3 space-y-3 list-decimal pl-5">
            <li className="font-medium text-base ">
              Any violation or breach of any term of these T&Cs.
            </li>
            <li className="font-medium text-base ">
              If the Service Provider, in the opinion of Garudayaan Logistics,
              misuses the Portal.
            </li>
            <li className="font-medium text-base ">
              failure to verify or authenticate Service Provider Information.
            </li>
            <li className="font-medium text-base ">
              Misbehavior, rude behavior with the staff of Garudayaan Logistics,
              customers or any other persons associated with Garudayaan
              Logistics.
            </li>
            <li className="font-medium text-base ">
              Any acts involving criminal offence punishable under law,
              including physical assault, threatening any of the staff of
              Garudayaan Logistics, person associated with Garudayaan Logistics
              and any other persons.
            </li>
            <li className="font-medium text-base ">
              Concealment of fact/material information while entering into a
              contract with Garudayaan Logistics.
            </li>
            <li className="font-medium text-base ">
              Poor & irregular for work, meetings and failure to abide by the
              rules/terms of the contract.
            </li>
            <li className="font-medium text-base ">
              Drunk while on duty and drunken behaviour.
            </li>
            <li className="font-medium text-base ">
              Commission of fraud/ misappropriation/embezzlement for undue
              monetary gain, which is against the interest of Garudayaan
              Logistics.
            </li>
            <li className="font-medium text-base ">
              Negligence in performing the duty, causing damage of moveable and
              immoveable assets of Garudayaan Logistics, its employees,
              customers.
            </li>
            <li className="font-medium text-base ">
              Indulging in spreading content through digital media, social
              networking sites, or any other form, which could be detrimental to
              Garudayaan Logistics brand and its image.
            </li>
            <li className="font-medium text-base ">
              Indulging in acts such as creating ruckus/ strike/ or any activity
              against Garudayaan Logistics, which could be detrimental to
              Garudayaan Logistics brand and its image.
            </li>
            <li className="font-medium text-base ">
              Indulging in unauthorized disclosure of confidential information
              of Garudayaan Logistics to external agency, person, Garudayaan
              Logistics or organization.
            </li>
            <li className="font-medium text-base ">
              Misuse of assets provided by Garudayaan Logistics, which could be
              detrimental to the interest of Garudayaan Logistics brand and its
              image.
            </li>
            <li className="font-medium text-base ">
              Absconding for more than 4 hours with any asset, item, money or
              any other valuable item belonging to Garudayaan Logistics, its
              employees, customers or other staff member(s).
            </li>
            <li className="font-medium text-base ">
              Failure to abide by any of the rules and guidelines given by
              Garudayaan Logistics as part of service quality standards and
              principles and/or Code of Conduct. or
            </li>
            <li className="font-medium text-base ">
              In case the background check, whether wholly or partially, is
              found negative at any point of time during the term of these T&Cs.
            </li>
          </ol>
        </ol>
      </div>

      <div className="mt-5">
        <h1 className="font-bold text-lg mt-2">ANNEXURE A</h1>
        <h1 className="font-bold text-lg mt-2">
          Details of Vehicles and Drivers with No Objection Certificate.
          Documents Required
        </h1>
        <ol className="mt-3 space-y-3 list-decimal pl-5">
          <li className="font-medium text-base ">PAN card Number</li>
          <li className="font-medium text-base ">Aadhaar Number and</li>
          <li className="font-medium text-base ">Driving License Number</li>
        </ol>
      </div>
    </div>
  );
};

export default Disclaimer;
